import React, { useState, } from 'react';
import { getOwnBalance } from './Web3Client';
import { Image2 } from './Webs3';
import './App.css';
import { Container, Navbar, Nav, NavDropdown, CardGroup, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import { image3 } from './Image2';
import { image4 } from './Image3';
import { image5 } from './Image4';
import { image6 } from './Image5';
import { image7 } from './Image6';
import { image8 } from './Image7';

function App() {


 

	// const [minted, setMinted] = useState(false);
	const [balance, setBalance] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const fetchBalance = () => {
		getOwnBalance()
			.then((balance) => {
				setBalance(balance);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance1, setBalance1] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const getchBalance = () => {
		Image2()
			.then((balance1) => {
				setBalance1(balance1);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance2, setBalance2] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const Image2Balance = () => {
		image3()
			.then((balance2) => {
				setBalance2(balance2);
			})
			.catch((err) => {
				console.log(err);
			});
	};


  const [balance3, setBalance3] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image3Balance = () => {
		image4()
			.then((balance3) => {
				setBalance3(balance3);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance4, setBalance4] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image4Balance = () => {
		image5()
			.then((balance4) => {
				setBalance4(balance4);
			})
			.catch((err) => {
				console.log(err);
			});
	};


  const [balance5, setBalance5] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image5Balance = () => {
		image6()
			.then((balance5) => {
				setBalance5(balance5);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance6, setBalance6] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image6Balance = () => {
		image7()
			.then((balance6) => {
				setBalance6(balance6);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance7, setBalance7] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image7Balance = () => {
		image8()
			.then((balance7) => {
				setBalance7(balance7);
			})
			.catch((err) => {
				console.log(err);
			});
	};

  const [balance8, setBalance8] = useState(0);

	// const mint = () => {
	// 	mintToken()
	// 		.then((tx) => {
	// 			console.log(tx);
	// 			setMinted(true);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// };

	const image8Balance = () => {
		image8()
			.then((balance7) => {
				setBalance8(balance7);
			})
			.catch((err) => {
				console.log(err);
			});
	};


  window.onload = function() {
    fetchBalance();
    getchBalance();
    Image2Balance();
    image3Balance();
    image4Balance();
    image5Balance();
    image6Balance();
    image7Balance();
    image8Balance();
      };

      let provider = window.ethereum;

      const web3 = new Web3(provider);

      if(balance > 0.000000000000000000) {
        const ahapb = document.getElementById("myImg")
        ahapb.src= "./image-nft-1.jpg";
           }

           if(balance1 > 0.000000000000000000) {
            const ahapb = document.getElementById("myImg1")
            ahapb.src= "image-nft-2-01.jpg";
               }

               if(balance2 > 0.000000000000000000) {
                const ahapb = document.getElementById("myImg2")
                ahapb.src= "image-nft-3-01.jpg";
                   }

                   if(balance3 > 0.000000000000000000) {
                    const ahapb = document.getElementById("myImg3")
                    ahapb.src= "./image-nft-4.jpg";
                       }

                       if(balance4 > 0.000000000000000000) {
                        const ahapb = document.getElementById("myImg4")
                        ahapb.src= "./image-nft-2.jpg";
                           }

                           if(balance5 > 0.000000000000000000) {
                            const ahapb = document.getElementById("myImg5")
                            ahapb.src= "./image-nft-3.jpg";
                               }

                               if(balance6 > 0.000000000000000000) {
                                const ahapb = document.getElementById("myImg6")
                                ahapb.src= "./image-nft-7.jpg";
                                   }

                                   if(balance7 > 0.000000000000000000) {
                                    const ahapb = document.getElementById("myImg7")
                                    ahapb.src= "./image-nft-5.jpg";
                                       }

                                       if(balance8 > 0.000000000000000000) {
                                        const ahapb = document.getElementById("myImg8")
                                        ahapb.src= "./image-nft-6.jpg";
                                           }
    


	return (
		<>
		<div className="App">
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Container>
  <Navbar.Brand href="#home"><img style={{width:50}} src="bchumanape-logo-1234121.png" /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      <Nav.Link href="#pieces">Pieces</Nav.Link>
      <Nav.Link href="#roadmap">Roadmap</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link href="https://opensea.io/BCHA">Opensea</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
		<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      Welcome to BCHumanApe!
    </h1>
			{/* {!minted ? (
				<button onClick={() => mint()}>Mint token</button>
			) : (
				<p>Token minted successfully!</p>
			)} */}
			{fetchBalance()}
      {getchBalance()}
      {Image2Balance()}
      {image3Balance()}
      {image4Balance()}
      {image5Balance()}
      {image6Balance()}
      {image7Balance()}
      {image8Balance()}

			<p style={{fontSize: 16 , paddingTop: 15}}>Welcome to BCHumanApe! Collect all the parts of the puzzle to obtain a reward. You can view the information of the parts from the descriptions below.</p>
			<div id="yaps">
			<p style={{fontSize: 16 , color:'red', fontWeight:'bold'}}>You are not connected with the correct network. Please make sure that you are connected on Polygon Mainnet.</p>
			</div>
	
	<center><table dir="ltr" border="1" cellspacing="0" cellpadding="0"><colgroup><col width="100" /><col width="100" /><col width="100" /></colgroup>
	<tbody>
	<tr>
	<td><img id="myImg" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg1" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg2" src="blankpiece.png" width="100"/></td>
	</tr>
	<tr>
	<td><img id="myImg3" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg4" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg5" src="blankpiece.png" width="100"/></td>
	</tr>
	<tr>
	<td><img id="myImg6" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg7" src="blankpiece.png" width="100"/></td>
	<td><img id="myImg8" src="blankpiece.png" width="100"/></td>
	</tr>
	</tbody>
	</table>
	</center>
	<CardGroup id="pieces" style={{marginTop: 30}}>
  <Card>
    <Card.Img variant="top" src="./image-nft-1.jpg"/>
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 1</Card.Title>
      <Card.Text>
        This is the first piece of BCHumanApe puzzle. It contains a part of character's hair.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
    <a href="https://opensea.io/collection/bchumanapepiece1"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-2-01.jpg" />
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 2</Card.Title>
      <Card.Text>
      This is the second piece of BCHumanApe puzzle. It contains a part of character's hair.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece2"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-3-01.jpg"/>
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 3</Card.Title>
      <Card.Text>
      This is the third piece of BCHumanApe puzzle. It contains a part of character's hair.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece3"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
</CardGroup>
<CardGroup style={{marginTop: 30}}>
  <Card>
    <Card.Img variant="top" src="image-nft-4.jpg" />
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 4</Card.Title>
      <Card.Text>
      This is the fourth piece of BCHumanApe puzzle. It contains a part of character's body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece4"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-2.jpg" />
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 5</Card.Title>
      <Card.Text>
      This is the fifth piece of BCHumanApe puzzle. It contains a part of character's body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece5"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-3.jpg"/>
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 6</Card.Title>
      <Card.Text>
      This is the sixth piece of BCHumanApe puzzle. It contains a part of character's body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece6"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
</CardGroup>
<CardGroup style={{marginTop: 30}}>
  <Card>
    <Card.Img variant="top" src="image-nft-7.jpg" />
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 7</Card.Title>
      <Card.Text>
      This is the seventh piece of BCHumanApe puzzle. It contains a part of character's lower body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece7"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-5.jpg" />
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 8</Card.Title>
      <Card.Text>
      This is the eight piece of BCHumanApe puzzle. It contains a part of character's lower body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece8"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src="image-nft-6.jpg"/>
    <Card.Body>
      <Card.Title>BCHumanApe Puzzle Piece 9</Card.Title>
      <Card.Text>
      This is the nineth piece of BCHumanApe puzzle. It contains a part of character's lower body.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
	<a href="https://opensea.io/collection/bchumanapepiece9"><small className="text-muted">Click here to lookup on OpenSea</small></a>
    </Card.Footer>
  </Card>
</CardGroup>
<div id="roadmap">
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      Roadmap
    </h1>
	<p style={{fontSize: 16}}> %10 - 91 Opening a Discord Channel on Sale</p>
	<p style={{fontSize: 16}}>%20 - 10k Online Twitter Followers</p>
	<p style={{fontSize: 16}}>%30 - Producing 5,000 BCHAs</p>
	<p style={{fontSize: 16}}>%50 - Generating BCHA Tokens</p>
	<p style={{fontSize: 16}}>%80 - BCHA Mutant Transformation Potion</p>
	<p style={{fontSize: 16}}>%100 - Exploring the BCHA Family</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      How to play BCHumanApe?
    </h1>
	<p style={{fontSize: 16}}>Log into the BCHumanApe website with the Metamask wallet.
The framework you purchased will be added to your Metamask wallet.
To use it, log back into the BCHumanApe website with your Metamask wallet.
Move the cursor over the square you purchased in your Metamask wallet and click the use button, the square you purchased will fit into place.
</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      What is BCHumanApe?
    </h1>
	<p style={{fontSize: 16}}>BCHumanApe is a puzzle game with at least 9 squares.
Those who complete these squares qualify for the rare character draw.
A certain number of puzzle pieces will be produced.
Puzzle characters have rare traits.
</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      What is BCHumanApe Lottery?
    </h1>
	<p style={{fontSize: 16}}>Those who combine the puzzle pieces released in limited numbers will participate in the draw.
The date of the draw and the winner will be announced via the social media tool.
Only 1 person will win the lottery.
The winner of the draw must be contacted within 1 month from the date of the draw.
If no communication is made with the winner of the draw, the draw will be held again.
</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      What is UVIP Ticket?
    </h1>
	<p style={{fontSize: 16}}>A UVIP ticket is a ticket issued to be used in the 10.001 BC project whose infrastructure is being developed.
</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      What are the Advantages of the UVIP Ticket?
    </h1>
<p style={{fontSize: 16}}>In our project, which will be released soon, those who have a UVIP ticket will benefit from discounts and advantages.
</p>
<h1 style={{marginTop: 10}} className="text-3xl font-bold underline">
      What is 10,001 BC
    </h1>
<p style={{fontSize: 16}}>10.001 BC is a community group for people with UVIP-VIP tickets.
And a certain part of the proceeds from it will be given to any charity.
</p>
	</div>
	</div>
	</>
	);
}
export default App;